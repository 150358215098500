import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import {
  Box,
  BoxProps,
  Container,
  Divider,
  styled,
  SxProps,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import Section from '_core/components/atoms/layout/Section'

import { getPrimaryTerms } from '_core/helpers/getTerms'
import Flex from '_core/components/atoms/layout/Flex'
import Button from '_core/components/atoms/buttons/Button'
import ExperienceButton from '_core/components/atoms/buttons/termButtons/ExperienceButton'
import NotAvailableBase from '_core/components/atoms/labels/NotAvailable'
import TermButtonGroup from '_core/components/molecules/buttonGroups/TermButtonGroup'
import MandateCard from '_core/components/molecules/cards/mandate/MandateCard'
import LanguageButtonGroup from '_core/components/molecules/buttonGroups/LanguageButtonGroup'
import MandateApplyButton from '_core/components/organisms/mandate/MandateApplyButton'
import FavoriteMandateButton from '_core/components/organisms/mandate/FavoriteMandateButton'
import MasonryGrid from '_core/components/organisms/masonry/MasonryGrid'

import TeamMemberContactCard from 'public/components/molecules/cards/teamMember/TeamMemberContactCard'
import SectionHeading from 'public/components/molecules/sections/SectionHeading'
import PageTitleHero from 'public/components/organisms/hero/PageTitleHero'
import Layout from 'public/components/organisms/global/Layout'
import TwoColContentLayout from 'public/components/organisms/layout/TwoColContentLayout'

interface HeadingProps extends BoxProps {
  first?: true
  extraSx?: SxProps
}

interface Props {
  data: Queries.MandateDetailQuery
}

const TermDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(4),
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}))

const NotAvailable = styled(NotAvailableBase)(({ theme }) => ({
  color: theme.palette.common.black,
}))

const Heading = ({ first, extraSx, ...props }: HeadingProps) => {
  return (
    <Box
      component="h2"
      sx={{
        typography: 'h4',
        lineHeight: 1,
        mt: first ? 0 : { xs: 4, sm: 5 },
        mb: { xs: 2, sm: 3 },
        ...extraSx,
      }}
      {...props}
    />
  )
}

const MandateDetail = ({ data }: Props) => {
  const theme = useTheme()
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'))
  const mandate = data.datoCmsPageMandate
  const relatedMandates = data.allDatoCmsPageMandate.edges

  // Add terms to the title and descriptions
  const primaryTerms = getPrimaryTerms({ content: mandate })
  let firstBullet = ''
  if (mandate.bulletPoints && mandate.bulletPoints.length > 0) {
    firstBullet = `${mandate.bulletPoints[0].bulletPoint}. `
  }
  const customDescription = `${firstBullet}${primaryTerms.join(', ')}`

  useEffect(() => {}, [])

  const applyButton = (
    <MandateApplyButton
      mandateId={mandate.originalId}
      heading={mandate.title}
      buttonProps={{
        sizeVariant: 'xxl',
        variant: 'contained',
        color: 'primary',
      }}
    />
  )

  const leftCol = (
    <>
      <Heading first>Requirements</Heading>

      <TermButtonGroup
        keyId={`${mandate.originalId}-requirements`}
        terms={{
          departments: mandate.departments,
          regions: mandate.regions,
          levels: mandate.levels,
          locations: mandate.locations,
        }}
        termButtonProps={{ size: 'xl' }}
      />
      <ExperienceButton
        min={mandate.minExperience}
        max={mandate.maxExperience}
        size="xl"
      />
      <TermDivider />

      <Heading>Experience</Heading>
      <TermButtonGroup
        keyId={`${mandate.originalId}-expereience`}
        terms={{
          secondaryDepartments: mandate.secondaryDepartments,
          secondaryRegions: mandate.secondaryRegions,
          products: mandate.products,
          sectors: mandate.sectors,
        }}
        termButtonProps={{ size: 'xl' }}
      />
      <LanguageButtonGroup
        keyId={`${mandate.originalId}-languages`}
        languages={mandate.languages}
        termButtonProps={{ size: 'xl' }}
      />
      <TermDivider />
      <Heading>Responsibilities</Heading>
      <TermButtonGroup
        keyId={`${mandate.originalId}-responsibilities`}
        terms={{
          responsibilities: mandate.responsibilities,
        }}
        termButtonProps={{ size: 'xl' }}
      />
      <Divider sx={{ mt: 4 }} />
      <Heading>Details</Heading>

      {mandate.bulletPoints.length > 0 && (
        <div className="large-text">
          {mandate.bulletPoints.map(
            (bullet: DatoCmsBulletPoint, index: number) => {
              return (
                <Box
                  component="p"
                  sx={{ typography: 'largeText' }}
                  key={`${bullet.originalId}-${index}`}
                >
                  {bullet.bulletPoint}
                </Box>
              )
            }
          )}
        </div>
      )}
      <Box display={{ xs: 'block', lg: 'none' }}>
        <Divider sx={{ mt: 4 }} />
        <Heading>Consultants</Heading>
        <Divider sx={{ mt: 3, mb: 4 }} />
        {mandate.consultants.map(
          (consultant: Queries.DatoCmsTeamMember, index: number) => (
            <div key={consultant.originalId}>
              <TeamMemberContactCard member={consultant} />
              {index + 1 < mandate.consultants.length && (
                <Divider sx={{ my: 3 }} />
              )}
            </div>
          )
        )}
      </Box>

      <Divider sx={{ mt: 4 }} />
      <Flex fullWidth alignCenter mt={4}>
        {!mandate.active ? <NotAvailable strong /> : <>{applyButton}</>}
        <Box ml="auto">
          <FavoriteMandateButton
            mandateId={mandate.originalId}
            mandateTitle={mandate.title}
            buttonProps={{
              variant: 'outlined',
              sizeVariant: 'xxl',
              colorVariant: 'outline-dark',
              round: true,
            }}
          />
        </Box>
      </Flex>
    </>
  )

  const rightCol = (
    <>
      {lgUp && (
        <>
          <Heading extraSx={{ mb: 2 }}>
            Consultant{mandate.consultants.length > 1 && <>s</>}
          </Heading>
          <Divider sx={{ mb: 3 }} />
          {mandate.consultants.map(
            (consultant: Queries.DatoCmsTeamMember, index: number) => (
              <div key={consultant.originalId}>
                <TeamMemberContactCard member={consultant} />
                {index + 1 < mandate.consultants.length && (
                  <Divider sx={{ my: 3 }} />
                )}
              </div>
            )
          )}
        </>
      )}
    </>
  )

  return (
    <Layout
      metadata={{
        seoTags: mandate.seoMetaTags,
        extraTitle: primaryTerms.join(', '),
        customDescription: customDescription,
        customImage: {
          src: mandate.metaImage[0].image.gatsbyImageData.images.fallback.src,
          width: 1200,
          height: 630,
        },
      }}
    >
      <PageTitleHero
        mainHeading={mandate.title}
        smallHeading="Mandates"
        imagePortrait={mandate.imagePortrait[0].image.gatsbyImageData}
        imageLandscape={mandate.imageLandscape[0].image.gatsbyImageData}
        twoColContentLayout
      >
        <Flex center fullWidth mt={5}>
          {!mandate.active ? (
            <Button
              disabled
              variant="outlined"
              sizeVariant="xl"
              colorVariant="outline-light"
            >
              <NotAvailable />
            </Button>
          ) : (
            <>{applyButton}</>
          )}
        </Flex>
      </PageTitleHero>
      <TwoColContentLayout
        leftCol={leftCol}
        rightCol={rightCol}
        shareButtonsProps={{
          url: mandate.slug,
          contentType: 'Mandate',
          description: mandate.title,
          source: 'detail-page',
        }}
      />

      <Section bgcolor="lightGrey" vSpace>
        <Container vertical-padding="true" maxWidth={false}>
          <SectionHeading heading="Related Mandates" variant="h2" />
          <MasonryGrid masonryLayout="compact" className="mandates-masonry">
            {relatedMandates.map((item: Queries.DatoCmsPageMandate) => {
              return (
                <MandateCard
                  key={`rm-${item.node.originalId}`}
                  mandate={item.node}
                />
              )
            })}
          </MasonryGrid>
        </Container>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query MandateDetail($slug: String!, $department: String!) {
    datoCmsPageMandate(slug: { eq: $slug }) {
      ...mandateCard
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      imageLandscape: locations {
        image {
          gatsbyImageData(
            width: 1600
            layout: FULL_WIDTH
            imgixParams: {
              ar: "16:5"
              fit: "crop"
              crop: "focalpoint"
              sat: -100
            }
          )
        }
      }
      imagePortrait: locations {
        image {
          gatsbyImageData(
            width: 900
            layout: FULL_WIDTH
            imgixParams: {
              ar: "3:4"
              fit: "crop"
              crop: "focalpoint"
              sat: -100
            }
          )
        }
      }
      metaImage: locations {
        image {
          gatsbyImageData(
            layout: FIXED
            imgixParams: {
              w: "1200"
              h: "630"
              fit: "crop"
              crop: "focalpoint"
            }
          )
        }
      }
    }
    allDatoCmsPageMandate(
      sort: { fields: [active, meta___createdAt], order: [DESC, DESC] }
      limit: 4
      filter: {
        slug: { ne: $slug }
        departments: { elemMatch: { slug: { eq: $department } } }
      }
    ) {
      edges {
        node {
          ...mandateCard
        }
      }
    }
  }
`
export default MandateDetail
