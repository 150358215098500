import termStructure, { termProps } from '_core/constants/term-structure'

export interface GetTermsProps {
  content: Queries.DatoCmsPageMandate | Queries.DatoCmsBlogPost
}

export const getPrimaryTerms = ({ content }: GetTermsProps) => {
  // Add terms to the title
  let primaryTerms: string[] = []
  Object.keys(termStructure.primary).map((key, index) => {
    const term: termProps =
      termStructure.primary[key as keyof typeof termStructure.primary]
    content[key].map(
      (
        term:
          | Queries.DatoCmsTermDepartment
          | Queries.DatoCmsTermRegion
          | Queries.DatoCmsTermLevel
          | Queries.DatoCmsTermLocation
      ) => {
        primaryTerms.push(term.name)
      }
    )
  })

  return primaryTerms
}
