export interface termProps {
  name: string
  fieldLabel: string
  dataSelector: string
}

export type primaryTerms = 'departments' | 'regions' | 'levels' | 'locations'
export type secondaryTerms =
  | 'products'
  | 'sectors'
  | 'languages'
  | 'responsibilities'
export type allTerms = primaryTerms | secondaryTerms

export interface termTypeProps {
  primaryType?: primaryTerms
  secondaryType?: secondaryTerms
}

interface termStructureProps {
  primary: {
    departments: termProps
    regions: termProps
    levels: termProps
    locations: termProps
  }
  secondary: {
    products: termProps
    sectors: termProps
    languages: termProps
    responsibilities: termProps
  }
}

const termStructure: termStructureProps = {
  primary: {
    departments: {
      name: 'Departments',
      fieldLabel: 'Areas of professional expertise',
      dataSelector: 'Department',
    },
    regions: {
      name: 'Regions',
      fieldLabel: 'Regional coverage',
      dataSelector: 'Region',
    },
    levels: {
      name: 'Levels',
      fieldLabel: 'Seniority',
      dataSelector: 'Level',
    },
    locations: {
      name: 'Locations',
      fieldLabel: 'Locational coverage',
      dataSelector: 'Location',
    },
  },
  secondary: {
    products: {
      name: 'Products',
      fieldLabel: 'Product specialisation',
      dataSelector: 'Product',
    },
    sectors: {
      name: 'Sectors',
      fieldLabel: 'Sector experience',
      dataSelector: 'Sector',
    },
    languages: {
      name: 'Languages',
      fieldLabel: 'Language proficiencies',
      dataSelector: 'Language',
    },
    responsibilities: {
      name: 'Responsibilities',
      fieldLabel: 'Responsibilities',
      dataSelector: 'Responsibility',
    },
  },
}

export default termStructure
